<template>
  <div>
    <v-progress-linear
      :active="getLoaderStatus"
      indeterminate
      fixed
      top
      color="primary"
      height="5px"
      style="z-index: 500"
    ></v-progress-linear>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-navigation-drawer
      :value="getToggleSlider"
      :key="`offer-1`"
      fixed
      temporary
      :stateless="keepOpen"
      overlay-opacity="0.8"
      right
      width="80%"
      class="fill-height"
    >
      <v-toolbar outlined flat dense light class="navbar-fixed-header">
        <v-btn icon class="mr-1" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <h5 class="ml-5 text-uppercase" v-if="formType === 'add'">
          Add Coupon
        </h5>
        <h5 class="ml-5 text-uppercase" v-if="formType === 'edit'">
          Update Coupon
        </h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-col class="max-width" v-if="form">
          <h6 class="ml-2">Coupon Information</h6>
          <v-col>
            <v-text-field
              label="Name"
              v-model="form.name"
              :rules="[getRules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Code"
              v-model="form.code"
              :rules="[getRules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Discount %"
              v-model="form.discount"
              :rules="[getRules.required, getRules.number, getRules.max]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Validity (Days)"
              v-model="form.validity"
              :rules="[getRules.required, getRules.number, getRules.isInteger]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Redemptions"
              v-model="form.redemptions"
              :rules="[getRules.required, getRules.number, getRules.isInteger]"
            ></v-text-field>
          </v-col>
          <v-btn color="primary" @click="validate" class="mt-5">
            <span v-if="formType === 'add'">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add Coupon
            </span>
            <span v-else>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Update Coupon
            </span>
          </v-btn>
        </v-col>
      </v-form>
    </v-navigation-drawer>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini"></top-nav-bar>
    <v-main
      :class="{ 'detail-content-margin': listingDrawer }"
      :style="[mini ? { 'padding-left': '56px' } : { 'padding-left': '232px' }]"
      class="details-content"
      v-if="getAuthUser.user_type === 'admin'"
    >
      <v-col>
        <v-card class="ma-5" elevation="15">
          <div>
            <div class="text-h6 pt-4 ma-2">Coupon Management</div>
            <v-divider class="mt-8 mb-4" color="default"></v-divider>
            <v-row class="ma-2">
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  clearable
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  color="primary"
                  outlined
                  @click="openCouponSlider('add')"
                >
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  New Coupon
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-4" color="default"></v-divider>
            <v-data-table
              :loading="false"
              :search="search"
              :headers="headers"
              :items="getCoupons"
              class="elevation-1"
            >
              <template v-slot:item.validity="{ item }">
                {{ daysText(item.validity) }}
              </template>
              <template v-slot:item.validity="{ item }">
                {{ daysText(item.validity) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="openCouponSlider('edit', item.id)"
                  >mdi-pencil</v-icon
                >

                <v-icon small @click="deleteCouponItem(item)"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Snackbar from "@/components/Snackbar";
const TopNavBar = () =>
  import(/* webpackChunkName: "top-nav-bar" */ "@/components/TopNavBar");
export default {
  name: "Coupons",
  components: { TopNavBar, Snackbar },
  data: () => ({
    mini: false,
    navigation: 0,
    listingDrawer: true,
    subscriptionDialog: false,
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Code", value: "code" },
      { text: "% Discount", value: "discount" },
      { text: "Validity", value: "validity" },
      { text: "Redemptions", value: "redemptions" },
      { text: "Actions", value: "actions", sortable: false, width: 80 },
    ],
    keepOpen: true,
    additionalSettings: false,
    form: null,
    snackbarMessage: null,
    formType: null,
    validForm: true,
  }),
  async mounted() {
    this.loadCoupons();
    this.initForm();
  },
  computed: {
    ...mapGetters([
      "getRules",
      "getAuthUser",
      "getCoupons",
      "getToggleSlider",
      "getNewCoupon",
      "getCouponAddStatus",
      "getCouponsLoadStatus",
      "getLoaderStatus",
      "getCoupon",
      "getCouponUpdateStatus",
      "getCouponDeleteStatus",
      "getCouponErrors",
    ]),
  },
  methods: {
    initForm() {
      let coupon = this.getNewCoupon;
      this.form = new Form(coupon);
    },
    minimizeList(status) {
      this.mini = status;
    },
    async openCouponSlider(state, id = null) {
      this.formType = state;
      if (state === "add") {
        this.setToggleSlider(true);
      } else if (state === "edit") {
        this.setCouponById(id);
        let coupon = await this.getCoupon;
        this.form = new Form(coupon);
        this.setToggleSlider(true);
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.formType === "add") {
          let payload = Object.assign({}, this.form);
          this.setNewCoupon(payload);
          await this.addCoupon();
          if (this.getCouponAddStatus === 2) {
            this.setToggleSlider(false);
            this.snackbarMessage = "Coupon Added Successfully";
            this.$refs.snackbar.type = "success";
            this.$refs.snackbar.show = true;
            this.loadCoupons();
            this.close();
            this.initForm();
          }
        } else if (this.formType === "edit") {
          let payload = Object.assign({}, this.form);
          this.setCoupon(payload);
          await this.updateCoupon();
          if (this.getCouponUpdateStatus === 2) {
            this.setToggleSlider(false);
            this.snackbarMessage = "Coupon Updated Successfully";
            this.$refs.snackbar.type = "success";
            this.$refs.snackbar.show = true;
            this.loadCoupons();
            this.close();
            this.initForm();
          }
        }
      }
    },
    close() {
      this.initForm();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.setToggleSlider(false);
    },
    daysText(days) {
      if (days > 1) {
        return days + " Days";
      } else if (days === 1) {
        return days + " Day";
      } else {
        return "N/A";
      }
    },
    capitalize(words) {
      if (words !== "" && words !== null) {
        return words.toUpperCase();
      }
    },
    async deleteCouponItem(item) {
      let couponItem = Object.assign({}, item);
      await this.deleteCoupon(couponItem);
      if (this.getCouponDeleteStatus === 3) {
        this.snackbarMessage = this.getCouponErrors.error;
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    ...mapActions([
      "logout",
      "loadCoupons",
      "addCoupon",
      "updateCoupon",
      "deleteCoupon",
    ]),
    ...mapMutations([
      "setToggleSlider",
      "setNewCoupon",
      "setCoupon",
      "setCouponById",
    ]),
  },
  watch: {},
};
</script>

<style scoped>
.max-width {
  max-width: 70%;
  margin: 0 auto;
}
.custom-upload-file {
  box-shadow: none !important;
  border: dashed 1px #9e9e9e !important;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
